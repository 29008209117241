body,
button,
div,
h1,
h2,
header,
main,
p {
  display: block;
  margin: 0;
  border: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
}

button {
  display: inline-block;
}
