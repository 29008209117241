/* Variables */
:root {
  --blue:      #5d87a1;
  --gray-dark: #464646;
  --gray:      #c4c4c4;
  --red:       #df1e36;
  --white:     #ffffff;
  --yellow:    #f7cb10;
}

/* Layout */
.absolute {position: absolute;}
.relative {position: relative;}

.hidden {display: none;}

.overflow-hidden   {overflow:   hidden;}
.overflow-x-hidden {overflow-x: hidden;}

.top-0 {top: 0;}

/* Flexbox */
.flex {display: flex;}

.flex-wrap {flex-wrap: wrap;}

.justify-center  {justify-content: center;}
.justify-end     {justify-content: end;}
.justify-between {justify-content: space-between;}

.flex-col {flex-direction: column;}

.items-center {align-items: center;}

/* Spacing */
.mx-auto {margin-right: auto; margin-left: auto;}
.mx-1    {margin-right: 1rem; margin-left: 1rem;}

.mr-1 {margin-right: 1rem;}

.mb-1 {margin-bottom: 1rem;}
.mb-2 {margin-bottom: 2rem;}
.mb-4 {margin-bottom: 4rem;}
.mb-8 {margin-bottom: 8rem;}

.p-1 {padding: 1rem;}
.p-4 {padding: 4rem;}

.py-1\/2 {padding-top: 0.5rem; padding-bottom: 0.5rem;}
.py-4    {padding-top: 4rem;   padding-bottom: 4rem;}

.px-1 {padding-right: 1rem; padding-left: 1rem;}
.px-2 {padding-right: 2rem; padding-left: 2rem;}
.px-4 {padding-right: 4rem; padding-left: 4rem;}

/* Sizing */
.w-full {width: 100%;}

.h-full {height: 100%;}

/* Typography */
.font-title {font-family: Oswald;}

.leading-2 {line-height: 2rem;}

.no-underline {text-decoration: none;}

.text-center {text-align: center;}

.text-gray-dark {color: var(--gray-dark);}
.text-red       {color: var(--red);}
.text-white     {color: var(--white);}

.text-xl {font-size: 40px;}

.tracking-wide {letter-spacing: 0.23rem;}

.uppercase {text-transform: uppercase;}

/* Backgrounds */
.bg-blue   {background-color: var(--blue);}
.bg-gray   {background-color: var(--gray);}
.bg-red    {background-color: var(--red);}
.bg-white  {background-color: var(--white);}
.bg-yellow {background-color: var(--yellow);}

/* Borders */
.border-1 {border-width: 1px;}
.border-2 {border-width: 2px;}

.border-red   {border-color: var(--red); border-style: solid;}
.border-white {border-color: var(--white); border-style: solid;}

/* Interactivity */
.pointer {cursor: pointer;}

.pointer-events-auto {pointer-events: auto;}
.pointer-events-none {pointer-events: none;}
