.lightbox {
  width: 100vw;
  height: 100vh;
}

.lightbox-arrow-left,
.lightbox-arrow-right  {
  /* margin-bottom: 112px; */
  font-size: 32px;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.lightbox-arrow-left:hover,
.lightbox-arrow-right:hover  {
  /* margin-bottom: 112px; */
  background: rgba(0, 0, 0, 0.7); 
}

.lightbox-arrow-left {
  left: 0;
}

.lightbox-arrow-right  {
  right: 0;
}

.lightbox-close {
  top: 1rem;
  right: 1rem;
  font-size: 32px;
}

.lightbox-iframe {
  /* margin-bottom: 112px; */
  width: calc(100vw - 5rem);
  height: calc((100vw - 5rem) * 360 / 640);
  max-height: calc(100vh - 176px);
}

.lightbox-overlay {
  background-color: black;
  opacity: 0.75;
}

.lightbox-thumbnail {
  width: 200px;
  height: 112px;
}

.lightbox-thumbnails {
  bottom: 0;
  overflow-x: auto;
}

.lightbox-title {
  width: calc(100vw - 5rem);
  text-align: center;
}

.logo {
  max-width: calc(100vw - 2rem);
}

.video-section {
  max-width: calc(1365px);
  width: 100vw;
}

.video-section-description {
  max-width: 725px;
  min-height: 466px;
}

.video-thumbnail-image {
  max-width: calc(100vw - 2rem);
  max-height: calc((100vw - 2rem) * 218 / 388);
  width: 388px;
  height: 218px;
  background-position: center center;
  background-size: cover;
}

.video-thumbnail-large-container {
  top: -42px;
}

.video-thumbnail-image-large {
  width: 673px;
  height: 378px;
}

.video-thumbnails {
  max-width: 1280px;
}

.video-thumbnails-squeeze {
  gap: calc((1280px - (388px * 3)) / 2); /* 58px */
}

.contact-davis:hover {
  background-color: var(--white);
  color: var(--red);
  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
}

.watch-our-reel:hover {
  background-color: var(--red);
  color: var(--white);
  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
}

@media only screen and (min-width: 900px) {
  .md\:px-4 {padding-right: 4rem; padding-left: 4rem;}

  .video-section-heading {
    flex-direction: row;
    margin-bottom: 4rem;
  }

  .video-thumbnail-image-large {
    max-width: calc(100vw - 420px);
    max-height: calc((100vw - 420px) * 378 / 673);
  }

  .video-thumbnail-large-container {
    top: unset;
    left: -42px;
    margin-top: 2rem;
  }
}
